import {download,http} from '@/http'
require('../mock')

//Request Api管理层
export default {
        //首页banner
        home_banners: data => http('get','/home/banners',data),
        //首页产品
        home_goods: data => http('get','/home/goods',data),
        //home_booth
        home_booth: data => http('get','/home/booths',data),
        //登陆
        login: (data) => http('post','/auth/login',data),
        //登陆二维码
        wx_login_qrcode: (data) => http('get','/weauth/qrcode',data),
        //登陆轮询
        wx_login_polling: (data) => http('get','/weauth/qrcode/polling',data),


        //发送短信验证码；
        send_sms_code: (data) => http('post','/sms/send_code',data),

        //商品列表
        good_list: (data) => http('get','/good/list',data),
        //商品信息
        good_info: data => http('get','/good/info',data),
        //商品评价
        good_rate: data => http('get','/good/rate',data),
        //商品详情
        good_detail: data => http('get','/good/detail',data),
        //添加/移除商品收藏
        good_collect: data => http('post','/good/collect',data),

        //get_goods_by_factory_id
        good_list_by_factory_id: data => http('get','/good/list/factory',data),

        //商品关联词
        good_query_words: data => http('get','/goods/query/words',data),

        //计算价格并返回sku等信息
        compute_good: data => http('post','/good/compute',data),

        //分类列表
        cate_list: (data) => http('get','/good/cates',data),

        //商家列表
        factory_list: (data) => http('get','/factorys',data),
        
        //商家详情
        factory_detail: data => http('get','/factory/detail',data),

        //区域列表
        zone_list: (data) => http('get','/zones',data),




        //////////购物车//////////////////
        //cart list
        cart_list: data => http('get','/cart/list',data),
        //cart add
        cart_add: data => http('post','/cart/add',data),
        //计算
        compute_cart: (data) => http('post','/cart/compute',data),


        //order
        submit_order: (data) => http('post','/order/submit',data),




        //我的
        //我的地址列表
        mine_addrs: data => http('get','/mine/addrs',data),

        //我的信息
        mine_info: data => http('get','/mine/info',data),

        //编辑个人信息
        edit_mine_info: data => http('put','/mine/info',data),

        //添加收货地址
        add_mine_addr: data => http('post','/mine/addr',data),

        //删除收货地址
        del_mine_addr: data => http('delete','/mine/addr',data),

        //我的订单列表
        mine_orders: data => http('get','/mine/orders',data),

        //催发货
        mine_order_urge: data => http('post','/mine/order/urge',data),

        //确认收货
        mine_order_confirm: data => http('post','/mine/order/confirm',data),

        //我收藏的商品
        mine_collect_goods: data => http('get','/mine/collect/goods',data),

        //评价
        set_mine_order_evaluation: data => http('post','/mine/order/evaluation',data),

        //获取评价
        get_mine_order_evaluation: data => http('get','/mine/order/evaluation',data),
        //获取评价商品
        get_mine_order_evaluation_goods: data => http('get','/mine/order/evaluation/goods',data),

        //订单物流
        mine_order_logistic: data => http('get','/mine/order/logistic',data),

        //get_orders_for_payment
        get_orders_for_payment: data => http('get','/mine/orders/for_payment',data),

        //支付
        payment: data => http('post','/order/payment',data),

        //支付状态
        payment_status: data => http('get','/order/payment/status',data) 
}