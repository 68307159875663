<template>
	<div>
                <el-dialog append-to-body :visible.sync="showCart" title="进货单" width="800px">
                        <div class="list" v-loading="computing" v-if="showCart">
                                <cart-list @close="showCart=false" @change="compute_total_price" @load="listOnLoad"></cart-list>
                        </div>
                        <div class="action">
                                <div class="text-center">
                                        合计：<span class="color-primary">¥{{total_price}}</span>
                                </div>
                                <div class="text-center pt-md">
                                        <el-button round type="primary" :disabled="list.length==0" @click="to_submit">立即结算</el-button>
                                </div>
                        </div>
                </el-dialog>
		<el-badge :value="$store.getters.get_cart_total" class="item">
			<el-button :size="adding?'medium':'small'"
				:type="adding?'danger':'warning'"
				circle
				icon="el-icon-shopping-cart-2"
				@click="showCart = true"
			></el-button>
		</el-badge>
	</div>
</template>
<script>
import CartList from './cart-list.vue'
import {debounce} from '@/util/index.js'
export default {
        watch:{
                '$store.getters.get_cart_total':{
                        handler(count) {
                                this.adding = true;
                                setTimeout(() => {
                                        this.adding = false;
                                },200)
                        }
                }
        },
        components:{
                CartList
        },
	data() {
		return {
                        adding:false,
                        showCart: false,
                        computing:false,
                        total_price:'200',
                        list:[]
		};
        },
        methods:{
                //计算价格
                async compute_total_price( list ) {
                        this.total_price = '计算中...'
                        this.computing = true;
                        let data = await this.$api.compute_cart({
                                list:list,
                        })
                        this.computing = false;
                        if(data) {
                                this.total_price = data.total_price;
                        } else {
                                this.total_price = '计算失败'
                        }
                },
                listOnLoad(data) {
                        this.total_price = data.total_price;
                        this.list = data.list;
                },

                to_submit() {
                        window.location.href = '/order_confirm.html'
                }
        }
};
</script>
<style lang="less" scoped>
        /deep/
        .el-dialog{
                margin-bottom: 0!important;
                display: flex;
                flex-direction: column;
                /deep/
                .el-dialog__body{
                        flex:1;
                        overflow: auto;
                        padding:0 20px 20px 20px !important;
                        display: flex;
                        flex-direction: column;
                        >.list{
                                flex:1;
                                overflow: hidden;
                        }
                        >.action{
                                padding-top:@pd-lg;
                        }
                }
        }
        @media screen and (min-width: 768px) {
                /deep/
                .el-dialog{
                        height:600px!important;
                        overflow: auto!important;
                }
        }
        @media screen and (max-width: 768px) {
                /deep/
                .el-dialog{
                        margin-top:0!important;
                        height:100%!important;
                        width:100%!important;
                        overflow: auto!important;
                }
        }
</style>