const Mock = require('mockjs')
const Random = Mock.Random;
Mock.setup({
        timeout:'200-500'
})
//获取我收藏的商品
Mock.mock(/\/mine\/collect\/goods/,'get', opt => {
        return {
                code:0,
                msg:'Success',
                data:{
                        total:100,
                        page_count:3,
                        page:1,
                        list:[
                                {
                                        id:1,
                                        name:'新款超声波导入仪多功能离子美容仪家用面部美容仪器清洁仪1',
                                        thumb:Random.image('50x50'),
                                        price:'19.9',//sku最低价
                                        created_at:'2020-09-09 09:00',
                                        factory_name:'上海XXX有限公司'
                                },
                                {
                                        id:1,
                                        name:'新款超声波导入仪多功能离子美容仪家用面部美容仪器清洁仪1',
                                        thumb:Random.image('50x50'),
                                        price:'19.9',//sku最低价
                                        created_at:'2020-09-09 09:00',
                                        factory_name:'上海XXX有限公司'
                                },
                                {
                                        id:1,
                                        name:'新款超声波导入仪多功能离子美容仪家用面部美容仪器清洁仪1',
                                        thumb:Random.image('50x50'),
                                        price:'19.9',//sku最低价
                                        created_at:'2020-09-09 09:00',
                                        factory_name:'上海XXX有限公司'
                                }
                        ]
                }
        }
})

//移除收藏
Mock.mock(/\/mine\/collect\/good/,'delete',opt => {
        return {
                code:0,
                msg:'Success',
                data:{
                        message:'移除成功'
                }
        }
})
//搜索关联词
Mock.mock('/good/query/words','get',opt => {
        return {
                code:0,
                msg:'Success',
                data:[
                        '关联词1',
                        '关联词2'
                ]
        }
})
//获取评价商品
Mock.mock(/\/mine\/order\/evaluation\/goods/,'get',opt => {
        return {
                code:0,
                msg:'Success',
                data:[
                        {
                                id:1,
                                name:'新款超声波导入仪多功能离子美容仪家用面部美容仪器清洁仪',
                                thumb:Random.image('40x40')
                        },
                        {
                                id:1,
                                name:'新款超声波导入仪多功能离子美容仪家用面部美容仪器清洁仪',
                                thumb:Random.image('40x40')
                        },
                        {
                                id:1,
                                name:'新款超声波导入仪多功能离子美容仪家用面部美容仪器清洁仪',
                                thumb:Random.image('40x40')
                        }
                ]
        }
})
//homebooths
Mock.mock('/home/booths','get',opt => {
        return {
                code:0,
                msg:'Success',
                data:[
                        {
                                href:'',
                                src:Random.image('400x150','#eeeeee','#ffffff')
                        },
                        {
                                href:'',
                                src:Random.image('400x150','#eeeeee','#ffffff')
                        },
                        {
                                href:'',
                                src:Random.image('400x150','#eeeeee','#ffffff')
                        }
                ]
        }
})
//home goods
Mock.mock(/\/home\/goods/,'get',opt => {
        console.log('mock',opt)
        var list = []
        for(var i=0;i<10;i++) {
                list.push({
                        id:i+1,
                        name:'【首页产品】新款超声波导入仪多功能离子美容仪家用面部美容仪器清洁仪',
                        thumb:'https://www.jrkj99.com/uploadfiles/pictures/product/20190905140458_0665.jpg',
                        has_video:1,
                        price:'998',
                        volume:23,
                        factory:{
                                id:323,
                                name:'上海XXX仪器设备有限公司'
                        }
                })
        }
        return {
                code:0,
                msg:'Success',
                data:{
                        page:1,
                        page_count:2,
                        total:42,
                        list:list
                }
        }
})
//home banners
Mock.mock('/home/banners','get',opt => {
        return {
                code:0,
                msg:'Success',
                data:[
                        {
                                src:Random.image('800x400','#eeeeee','#ffffff'),
                                href:'http://www.baidu.com'
                        },{
                                src:Random.image('800x400','#eeeeee','#ffffff'),
                                href:'http://www.baidu.com'
                        },{
                                src:Random.image('800x400','#eeeeee','#ffffff'),
                                href:'http://www.baidu.com'
                        },
                ]
        }
})


//商品分类
Mock.mock(/\/good\/cates/,'get',( options ) => {
        var list = []
        for(var i=0;i<8;i++) {
                list.push({
                        id:i+1,
                        name:'分类'+(i+1)
                })
        }
        return {
                code:0,
                msg:'Success',
                data:list
        }
})

//根据商户ID获取商品列表
Mock.mock(/\/good\/list\/factory/,'get',opt => {
        console.log(opt)
        var list = []
        for(var i=0;i<10;i++) {
                list.push({
                        id:i+1,
                        name:'新款超声波导入仪多功能离子美容仪家用面部美容仪器清洁仪',
                        thumb:'https://www.jrkj99.com/uploadfiles/pictures/product/20190905140458_0665.jpg',
                        has_video:1,
                        price:'998',
                        volume:23,
                        factory:{
                                id:323,
                                name:'上海XXX仪器设备有限公司'
                        }
                })
        }
        return {
                code:0,
                msg:'Success',
                data:{
                        page:1,
                        page_count:2,
                        total:42,
                        list:list
                }
        }
})

//商品列表
Mock.mock(/\/good\/list/,'get',( options ) => {
        console.log('mock',options)
        var list = []
        for(var i=0;i<10;i++) {
                list.push({
                        id:i+1,
                        name:'新款超声波导入仪多功能离子美容仪家用面部美容仪器清洁仪',
                        thumb:'https://www.jrkj99.com/uploadfiles/pictures/product/20190905140458_0665.jpg',
                        has_video:1,
                        price:'998',
                        volume:23,
                        factory:{
                                id:323,
                                name:'上海XXX仪器设备有限公司'
                        }
                })
        }
        return {
                code:0,
                msg:'Success',
                data:{
                        page:1,
                        page_count:2,
                        total:42,
                        list:list
                }
        }
})
//获取商品评价列表
Mock.mock(/\/good\/rate/,'get',opt => {
        return {
                code:0,
                msg:'Success',
                data:{
                        page:1,
                        page_count:2,
                        total:23,
                        list:[
                                {
                                        id:1,
                                        name:'华***烁',
                                        avatar:'https://www.jrkj99.com/uploadfiles/pictures/product/20190905140458_0665.jpg',
                                        rate:4.5,
                                        message:'这个商品棒极了',
                                        created_at:'2020-09-09 09:00'
                                }
                        ]
                }
        }
})
//收藏/或取消收藏
Mock.mock('/good/collect','post', opt => {
        return {
                code:0,
                msg:'Success',
                data:{
                        iscollected:1,//是否已收藏
                        collect_count:20123,//该商品当前收藏数量
                }
        }
})
//商品信息
Mock.mock(/\/good\/info/,'get',opt => {
        return {
                code:0,
                msg:'Success',
                data:{
                        id:1,
                        name:'新款超声波导入仪多功能离子美容仪家用面部美容仪器清洁仪',
                        stock:123981,
                        unit:'台',
                        isbuyed:1,//是否是已购买过的产品
                        iscollected:0,//是否已收藏
                        video:{
                                poster:'https://www.jrkj99.com/uploadfiles/pictures/product/20190905140458_0665.jpg?id=1',//视频封面
                                src:'src',
                        },
                        images:[
                                'https://www.jrkj99.com/uploadfiles/pictures/product/20190905140458_0665.jpg?id=1',
                                'https://www.jrkj99.com/uploadfiles/pictures/product/20190905140458_0665.jpg?id=2',
                                'https://www.jrkj99.com/uploadfiles/pictures/product/20190905140458_0665.jpg?id=3',
                                'https://www.jrkj99.com/uploadfiles/pictures/product/20190905140458_0665.jpg?id=4',
                                'https://www.jrkj99.com/uploadfiles/pictures/product/20190905140458_0665.jpg?id=5',
                        ],
                        min_price:'2020',//sku最低价
                        max_price:'2022',//sku最高价
                        spec:[ //规格
                                {
                                        id:1,//specid
                                        label:'颜色分类',
                                        options:[
                                                {
                                                        id:1,//optionid
                                                        label:'红色',
                                                        image:'https://www.jrkj99.com/uploadfiles/pictures/product/20190905140458_0665.jpg?id=1'
                                                },
                                                {
                                                        id:2,
                                                        label:'蓝色',
                                                        image:'https://www.jrkj99.com/uploadfiles/pictures/product/20190905140458_0665.jpg?id=1'
                                                }
                                        ]
                                },
                                {
                                        id:2,//specid
                                        label:'尺码',
                                        options:[
                                                {
                                                        id:333,
                                                        label:'小号'
                                                },
                                                {
                                                        id:444,
                                                        label:'大号'
                                                }
                                        ]
                                }
                        ],
                        sku:[
                                {
                                        id:1,//skuid
                                        key:'1-333',
                                        name:'颜色分类：红色；尺码：小号',
                                        alias:'红色-小号',
                                        stock:222,//库存
                                        incart:2,//该sku在购物车里的数量
                                        price:[
                                                {
                                                        num:2,//购买数量大于等于2
                                                        price:'100.00',
                                                },
                                                {
                                                        num:50,//购买数量大于等于50
                                                        price:'80.00'
                                                },
                                        ]
                                },
                                {
                                        id:2,//skuid
                                        key:'1-444',
                                        name:'颜色分类：红色；尺码：大号',
                                        alias:'红色-大号',
                                        stock:333,//库存
                                        incart:0,//该sku在购物车里的数量
                                        price:[
                                                {
                                                        num:5,//购买数量大于等于2
                                                        price:'100.00',
                                                },
                                                {
                                                        num:50,//购买数量大于等于50
                                                        price:'80.00'
                                                },
                                        ]
                                },
                                {
                                        id:3,//skuid
                                        key:'2-333',
                                        name:'颜色分类：蓝色；尺码：小号',
                                        alias:'蓝色-小号',
                                        stock:444,//库存
                                        incart:0,//该sku在购物车里的数量
                                        price:[
                                                {
                                                        num:2,//购买数量大于等于2
                                                        price:'100.00',
                                                },
                                                {
                                                        num:50,//购买数量大于等于50
                                                        price:'80.00'
                                                },
                                        ]
                                },
                                {
                                        id:4,//skuid
                                        key:'2-444',
                                        name:'颜色分类：蓝色；尺码：大号',
                                        alias:'蓝色-大号',
                                        stock:555,//库存
                                        incart:0,//该sku在购物车里的数量
                                        price:[
                                                {
                                                        num:2,//购买数量大于等于2
                                                        price:'100.00',
                                                },
                                                {
                                                        num:50,//购买数量大于等于50
                                                        price:'80.00'
                                                },
                                        ]
                                },
                        ],
                        factory:{
                                id:1,
                                name:'上海XXX美容仪器设备有限公司'
                        },
                        collect_count:1992,//收藏人数
                        volume:122,//销量
                        services:[//服务承诺
                                '七天无理由退换货',
                                '正品保证',
                                '商家承担物流'
                        ]
                }
        }
})

//商品内容详情
Mock.mock(/\/good\/detail/,'get', opt => {
        return {
                code:0,
                msg:'Success',
                data:{
                        html:'<h1>HTML DOM</h1>'
                }
        }
})
//商家详情
Mock.mock(/\/factory\/detail/,'get',opt => {
        return {
                code:0,
                msg:'Success',
                data:{
                        id:1,
                        name:'上海xxx仪器设备有限公司',
                        logo:Random.image('100x100'),
                        banner:Random.image('1200x400'),
                        desc:'致力于生产美容仪器设备的公司,致力于生产美容仪器设备的公司致力于生产美容仪器设备的公司致力于生产美容仪器设备的公司致力于生产美容仪器设备的公司',
                        mains:['美容仪','招聘','拓客'],
                        addr:'浙江 温州',
                        tags:['支持定制','资深商家'],
                }
        }
})
//商家列表
Mock.mock(/\/factorys/,'get',options => {
        console.log(options);
        var list = []
        for(var i =0;i<10;i++) {
                list.push({
                        id:i+1,
                        name:'上海XXX仪器设备有限公司',
                        mains:['美容仪','招聘','拓客'],
                        addr:'浙江 温州',
                        tags:['支持定制','资深商家'],
                        goods:[
                                {
                                        id:1,
                                        thumb:'https://www.jrkj99.com/uploadfiles/pictures/product/20190905140458_0665.jpg'
                                },
                                {
                                        id:2,
                                        thumb:'https://www.jrkj99.com/uploadfiles/pictures/product/20190905140458_0665.jpg'
                                },
                                {
                                        id:3,
                                        thumb:'https://www.jrkj99.com/uploadfiles/pictures/product/20190905140458_0665.jpg'
                                },
                                {
                                        id:4,
                                        thumb:'https://www.jrkj99.com/uploadfiles/pictures/product/20190905140458_0665.jpg'
                                }
                        ]
                })
        }
        return {
                code:0,
                msg:'Success',
                data:{
                        page:1,
                        page_count:2,
                        total:33,
                        list:list,
                }
        }
})

Mock.mock(/\/zones/,'get',options => {
        return {
                code:0,
                msg:'success',
                data:[
                        {
                                id:1,
                                label:'浙江'
                        },
                        {
                                id:2,
                                label:'安徽'
                        },
                        {
                                id:3,
                                label:'江苏'
                        },
                        {
                                id:4,
                                label:'上海'
                        }
                ]
        }
})
var count = 0;
Mock.mock(/\/weauth\/qrcode\/polling/,'get',options => {
        count=count+1;
        return {
                code:0,
                msg:'Success',
                data:{
                        status:count<5?0:1,
                        token:count>=5?'testtoken123':null
                }
        }
})

Mock.mock('/weauth/qrcode','get',options => {
        return {
                code:0,
                msg:'Success',
                data:{
                        src:'https://mp.weixin.qq.com/cgi-bin/showqrcode?ticket=gQH27zwAAAAAAAAAAS5odHRwOi8vd2VpeGluLnFxLmNvbS9xLzAyRkI4ZDlZMjZib1QxQ2NhR2h2Y1EAAgR8PKpfAwQQDgAA',
                        qrid:'oqijws912j09sj0923he09d239uo9js9812aj=='
                }
        }
})


//发送短信
Mock.mock('/sms/send_code','post',opt => {
        return {
                code:0,
                msg:'Success',
                data:{
                        wait:120,
                        send_id:'1230j'
                }
        }
})

Mock.mock('/auth/login','post',opt => {
        return {
                code:0,
                msg:'登陆成功',
                data:{
                        token:'9j1920js01'
                }
        }
})

//提交订单
Mock.mock('/order/submit','post',opt => {
        console.log(opt.body)
        return {
                code:0,
                msg:'Success',
                data:{
                        trade_id:'12345'
                }
        }
})

//根据交易ID与订单号获取我的订单信息
Mock.mock(/\/mine\/orders\/for_payment/,'get', opt => {
        console.log(opt)
        return {
                code:0,
                msg:'Success',
                data:{
                        total_price:'1998',
                        orders:[
                                {
                                        id:1,
                                        order_number:'202011121413000011',//订单编号
                                        factory:{
                                                id:1,
                                                name:'上海XXX美容仪器设备有限公司'
                                        },
                                        goods:[
                                                {
                                                        id:1221,//商品ID
                                                        name:'新款超声波导入仪多功能离子美容仪家用面部美容仪器清洁仪1',
                                                        thumb:'https://www.jrkj99.com/uploadfiles/pictures/product/20190905140458_0665.jpg',
                                                        sku_id:233,
                                                        sku_name:'颜色分类：红色；尺寸：大型',
                                                        count:1,
                                                        price:'50'
                                                },
                                                {
                                                        id:1222,//商品ID
                                                        name:'新款超声波导入仪多功能离子美容仪家用面部美容仪器清洁仪2',
                                                        thumb:'https://www.jrkj99.com/uploadfiles/pictures/product/20190905140458_0665.jpg',
                                                        sku_id:233,
                                                        sku_name:'颜色分类：红色；尺寸：大型',
                                                        count:2,
                                                        price:'50'
                                                }
                                        ],
                                        total_price:'100'
                                },
                                {
                                        id:2,
                                        order_number:'202011121413000012',//订单编号
                                        factory:{
                                                id:1,
                                                name:'上海XXX美容仪器设备有限公司'
                                        },
                                        goods:[
                                                {
                                                        id:1222,//商品ID
                                                        name:'新款超声波导入仪多功能离子美容仪家用面部美容仪器清洁仪3',
                                                        thumb:'https://www.jrkj99.com/uploadfiles/pictures/product/20190905140458_0665.jpg',
                                                        sku_id:233,
                                                        sku_name:'颜色分类：红色；尺寸：大型',
                                                        count:2,
                                                        price:'50'
                                                }
                                        ],
                                        total_price:'50'
                                }
                        ]
                }
        }
})

//计算并获取sku信息
Mock.mock('/good/compute','post' ,opt => {
        return {
                code:0,
                msg:'Success',
                data:{
                        factory_id:2,
                        factory_name:'上海XXX美容仪器有限公司',
                        good:{
                                id:2,
                                name:'新款超声波导入仪多功能离子美容仪家用面部美容仪器清洁仪',
                                thumb:'https://www.jrkj99.com/uploadfiles/pictures/product/20190905140458_0665.jpg',
                                sku_id:233,
                                sku_name:'颜色分类：红色；尺寸：大型',
                                price:'998',//
                                count:1,
                        },
                        total_price:'50',//店铺合计
                },
        }
})

Mock.mock(/\/cart\/list/,'get',opt => {
        return {
                code:0,
                msg:'Success',
                data:{
                        total_price:'100',
                        list:[
                                {
                                        factory_id:2,
                                        factory_name:'上海XXX美容仪器有限公司',
                                        goods:[
                                                {
                                                        id:2,
                                                        name:'新款超声波导入仪多功能离子美容仪家用面部美容仪器清洁仪',
                                                        thumb:'https://www.jrkj99.com/uploadfiles/pictures/product/20190905140458_0665.jpg',
                                                        sku_id:233,
                                                        sku_name:'颜色分类：红色；尺寸：大型',
                                                        price:'998',//
                                                        count:1,
                                                },
                                                {
                                                        id:2,
                                                        name:'新款超声波导入仪多功能离子美容仪家用面部美容仪器清洁仪',
                                                        thumb:'https://www.jrkj99.com/uploadfiles/pictures/product/20190905140458_0665.jpg',
                                                        sku_id:233,//用户选择
                                                        sku_name:'颜色分类：红色；尺寸：大型',
                                                        price:'998',
                                                        count:1,
                                                }
                                        ],
                                        total_price:'50',//店铺合计
                                },
                                {
                                        factory_id:2,
                                        factory_name:'上海XXX美容仪器有限公司',
                                        goods:[
                                                {
                                                        id:2,
                                                        name:'新款超声波导入仪多功能离子美容仪家用面部美容仪器清洁仪',
                                                        thumb:'https://www.jrkj99.com/uploadfiles/pictures/product/20190905140458_0665.jpg',
                                                        sku_id:233,
                                                        sku_name:'颜色分类：红色；尺寸：大型',
                                                        price:'998',
                                                        count:1,
                                                },
                                                {
                                                        id:2,
                                                        name:'新款超声波导入仪多功能离子美容仪家用面部美容仪器清洁仪',
                                                        thumb:'https://www.jrkj99.com/uploadfiles/pictures/product/20190905140458_0665.jpg',
                                                        sku_id:233,
                                                        sku_name:'颜色分类：红色；尺寸：大型',
                                                        price:'998',
                                                        count:1,
                                                },
                                                {
                                                        id:2,
                                                        name:'新款超声波导入仪多功能离子美容仪家用面部美容仪器清洁仪',
                                                        thumb:'https://www.jrkj99.com/uploadfiles/pictures/product/20190905140458_0665.jpg',
                                                        sku_id:233,
                                                        sku_name:'颜色分类：红色；尺寸：大型',
                                                        price:'998',
                                                        count:1,
                                                },
                                                {
                                                        id:2,
                                                        name:'新款超声波导入仪多功能离子美容仪家用面部美容仪器清洁仪',
                                                        thumb:'https://www.jrkj99.com/uploadfiles/pictures/product/20190905140458_0665.jpg',
                                                        sku_id:233,
                                                        sku_name:'颜色分类：红色；尺寸：大型',
                                                        price:'998',
                                                        count:1,
                                                },
                                                {
                                                        id:2,
                                                        name:'新款超声波导入仪多功能离子美容仪家用面部美容仪器清洁仪',
                                                        thumb:'https://www.jrkj99.com/uploadfiles/pictures/product/20190905140458_0665.jpg',
                                                        sku_id:233,
                                                        sku_name:'颜色分类：红色；尺寸：大型',
                                                        price:'998',
                                                        count:1,
                                                }
                                        ],
                                        total_price:'50',//店铺合计
                                }
                        ]
                }
        }
})
//加入购物车
Mock.mock('/cart/add','post',opt => {
        console.log(opt.body)
        return {
                code:0,
                msg:'Success',
                data:{
                        total:98
                }
        }
})
Mock.mock('/cart/compute','post',opt => {
        console.log(opt.body)
        return {
                code:0,
                msg:'Success',
                data:{
                        total_price:'998'
                }
        }
})

//确认收货
Mock.mock('/mine/order/confirm','post',opt => {
        return {
                code:0,
                msg:'Success',
                data:{
                        message:'已确认收货'
                }
        }
})

//催发货
Mock.mock('/mine/order/urge','post',opt => {
        return {
                code:0,
                msg:'Success',
                data:{
                        message:'催促成功'
                }
        }
})



//获取我的评价
Mock.mock(/\/mine\/order\/evaluation/,'get',opt => {
        return {
                code:0,
                msg:'Success',
                data:[
                        {
                                good_info:{
                                        id:1,
                                        name:'新款超声波导入仪多功能离子美容仪家用面部美容仪器清洁仪',
                                        thumb:Random.image('40x40')
                                },
                                evaluation:{
                                        is_anonymous:1,
                                        rate:5,
                                        message:'挺好的',
                                }
                        },
                        {
                                good_info:{
                                        id:1,
                                        name:'新款超声波导入仪多功能离子美容仪家用面部美容仪器清洁仪',
                                        thumb:Random.image('40x40')
                                },
                                evaluation:{
                                        is_anonymous:1,
                                        rate:4.5,
                                        message:'挺好的',
                                }
                        }
                ]
        }
})

//提交评价
Mock.mock('/mine/order/evaluation','post', opt => {
        console.log(opt.body)
        return {
                code:0,
                msg:'Success',
                data:{
                        message:'评价成功'
                }
        }
})

//物流
Mock.mock(/\/mine\/order\/logistic/,'get', opt => {
        return {
                code:0,
                msg:'Success',
                data:[
                        {
                                goods:[
                                        {
                                                id:1,
                                                thumb:Random.image('50x50'),
                                                name:'新款超声波导入仪多功能离子美容仪家用面部美容仪器清洁仪1',
                                                sku_alias:'红色-大号',
                                                count:1,
                                        },
                                        {
                                                id:1,
                                                thumb:Random.image('50x50'),
                                                name:'新款超声波导入仪多功能离子美容仪家用面部美容仪器清洁仪1',
                                                sku_alias:'红色-大号',
                                                count:1,
                                        }
                                ],
                                logistic_number:'30209312839123',
                                logistic_company:'圆通速递',
                                logistic:[
                                        {
                                                context:'即将发往XXX',
                                                time:'2020-09-09 09:00:00'
                                        },
                                        {
                                                context:'XXX网点已揽收',
                                                time:'2020-09-09 09:00:00'
                                        }
                                ]
                        },
                        {
                                goods:[
                                        {
                                                id:1,
                                                thumb:Random.image('50x50'),
                                                name:'新款超声波导入仪多功能离子美容仪家用面部美容仪器清洁仪1',
                                                sku_alias:'红色-小号',
                                                count:1,
                                        }
                                ],
                                logistic_number:'30209312839123',
                                logistic_company:'圆通速递',
                                logistic:[
                                        {
                                                context:'XXX网点已揽收',
                                                time:'2020-09-09 09:00:00'
                                        }
                                ]
                        }
                ]
        }
})

//我的订单列表
Mock.mock(/\/mine\/orders/,'get',opt => {
        return {
                code:0,
                msg:'Success',
                data:{
                        page:1,
                        page_count:2,
                        list:[
                                {
                                        id:1,
                                        trade_id:212,
                                        status:3,//0未付款，1已付款（待发货），2已发货，3已完成
                                        created_at:'2020-09-09 09:00',
                                        payment_time:'2020-09-09 09:00',//付款时间
                                        payment_type:'支付宝支付',
                                        is_evaluated:0,//是否已经评价过了
                                        order_number:'202011121413000011',//订单编号
                                        factory:{
                                                id:1,
                                                name:'上海XXX美容仪器设备有限公司'
                                        },
                                        goods:[
                                                {
                                                        id:1221,//商品ID
                                                        name:'新款超声波导入仪多功能离子美容仪家用面部美容仪器清洁仪1',
                                                        thumb:'https://www.jrkj99.com/uploadfiles/pictures/product/20190905140458_0665.jpg',
                                                        sku_id:233,
                                                        sku_name:'颜色分类：红色；尺寸：大型',
                                                        count:1,
                                                        price:'50'
                                                },
                                                {
                                                        id:1222,//商品ID
                                                        name:'新款超声波导入仪多功能离子美容仪家用面部美容仪器清洁仪2',
                                                        thumb:'https://www.jrkj99.com/uploadfiles/pictures/product/20190905140458_0665.jpg',
                                                        sku_id:233,
                                                        sku_name:'颜色分类：红色；尺寸：大型',
                                                        count:2,
                                                        price:'50'
                                                }
                                        ],
                                        total_price:'100',//总价
                                        discount:'0',
                                        real_price:'80',//实付款
                                },
                        ]
                }
        }
})
///我的
Mock.mock('/mine/info','get',opt => {
        return {
                code:0,
                msg:'SUccess',
                data:{
                        id:23,
                        username:'胡先生',
                        avatar:Random.image('80x80','#eee','#fff'),
                        phone:'15925789407',
                }
        }
})
//修改info
Mock.mock('/mine/info','put',opt => {
        console.log(opt)
        return {
                code:0,
                msg:'Success',
                data:{
                        state:1,//修改成功状态
                        message:'修改成功'
                }
        }
})
Mock.mock('/mine/addrs','get',opt => {
        return {
                code:0,
                msg:'Success',
                data:[
                        {
                                id:1,
                                name:'华小烁',
                                phone:'15000000000',
                                address:'浙江省 金华市 义乌市 银海一区39栋'
                        },
                        {
                                id:2,
                                name:'华小烁2',
                                phone:'15000000000',
                                address:'浙江省 金华市 义乌市 银海一区39栋'
                        }
                ]
        }
})

//添加
Mock.mock('/mine/addr','post', opt => {
        return {
                code:0,
                msg:'Success',
                data:{
                        id:3,
                        name:'华小烁2',
                        phone:'15000000000',
                        address:'浙江省 金华市 义乌市 银海一区39栋'
                }
        }
})
//删除成功
Mock.mock('/mine/addr','delete', opt => {
        return {
                code:0,
                msg:'Success',
                data:{
                        message:'删除成功'
                }
        }
})

//获取支付状态
Mock.mock(/\/order\/payment\/status/,'get',opt => {
        return {
                code:0,
                msg:'Success',
                data:{
                        status:0,
                }
        }
})
//获取支付跳转链接
Mock.mock('/order/payment','post', opt => {
        return {
                code:0,
                msg:'Success',
                data:{
                        payment_url:'/payment_result.html?trade_id=12&order_id=34',
                }
        }
})

