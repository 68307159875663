import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate"
Vue.use(Vuex)

export default new Vuex.Store({
  plugins:[
    createPersistedState()
  ],
  state: {
    mine_info:null,
    cart_total:0,
  },
  mutations: {
    set_cart_total(state,payload) {
      state.cart_total = payload
    },
    set_mine_info(state,data) {
      state.mine_info = data;
    }
  },
  getters:{
    get_cart_total(state) {
      return state.cart_total;
    },
    get_mine_info(state) {
      return state.mine_info;
    },
    get_mine_username(state) {
      return state.mine_info?state.mine_info.username:'...'
    }
  },
  actions: {
    async load_mine_info(ctx, api) {
      let data = await api();
      if(data) {
        ctx.commit('set_mine_info',data);
      } else {
        ctx.commit('set_mint_info',null)
      }
    }
  },
  modules: {
  }
})
