<template>
        <div class="address text-center">
                <div class="list" v-loading="loading">
                        <el-alert class="item" v-for="(item,index) in list" :key="index" :type="item.id==active && !manage?'success':'info'" :show-icon="item.id==active && !manage" @click.native="active=item.id==active || manage?null:item.id" :closable="false">
                                <div slot="title">收货人：{{item.name}}</div>
                                <div class="other">
                                        {{item.address}}
                                        <div class="phone">
                                                手机：{{item.phone}}
                                        </div>
                                        <div class="pt-sm" v-if="manage">
                                                <el-button size="mini" @click="del_addr(item)">删除</el-button>
                                        </div>
                                </div>
                        </el-alert>
                </div>
                <el-button icon="el-icon-plus" @click="addAddress">添加新地址</el-button>
                <el-dialog append-to-body title="添加新地址" :visible.sync="visible" width="500px">
                        <el-form :model="addForm" :rules="addRules" ref="addform">
                                <el-row :gutter="20">
                                        <el-col :xs="24" :sm="8">
                                                <el-form-item label="收货人" prop="name">
                                                        <el-input clearable placeholder="收货人姓名" :maxlength="10" v-model="addForm.name"></el-input>
                                                </el-form-item>
                                        </el-col>
                                        <el-col :xs="24" :sm="16">
                                                <el-form-item label="手机号" prop="phone">
                                                        <el-input clearable placeholder="收货人手机号" :maxlength="11" v-model="addForm.phone"></el-input>
                                                </el-form-item>
                                        </el-col>
                                        <el-col :span="24">
                                                <el-form-item label="详细地址" prop="addr">
                                                        <el-input clearable :maxlength="50" show-word-limit :rows="3" type="textarea" placeholder="省-市-区县-街道门牌号" v-model="addForm.addr"></el-input>
                                                </el-form-item>
                                        </el-col>
                                        <el-col :span="24">
                                                <el-button style="width:100%;" type="primary" @click="saveAddr" :loading="adding">保存</el-button>
                                        </el-col>
                                </el-row>
                        </el-form>
                </el-dialog>
        </div>
</template>
<script>
export default {
        props:{
                manage:Boolean
        },
        watch:{
                active:{
                        handler(active) {
                                this.$emit('pick',active)
                        }
                }
        },
        created() {
                this.loadAddress()
        },
        methods:{
                del_addr( item ) {
                        this.$confirm('确定删除吗？','确认')
                        .then( async () => {
                                let data = await this.$api.del_mine_addr({
                                        id:item.id,
                                })
                                if(data) {
                                        this.$message.success('删除成功')
                                } else {
                                        this.$message.error('删除失败')
                                }
                        })
                },
                addAddress() {
                        this.visible = true;
                },
                async loadAddress() {
                        this.loading = true;
                        let data = await this.$api.mine_addrs()
                        this.loading = false;
                        if(data) {
                                this.list = data
                                if(this.list.length > 0) {
                                        this.active = this.list[0].id;
                                        this.$emit('pick',this.active)
                                }
                        }
                },
                saveAddr() {
                        this.$refs['addform'].validate(async (valid) => {
                                if(!valid) return;
                                this.adding = true;
                                let data = await this.$api.add_mine_addr()
                                this.adding = false;
                                if(data) {
                                        this.list.push(data)
                                        this.active = data.id;
                                        this.visible = false;
                                }
                        })
                }
        },
        data() {
                return {
                        loading:false,
                        visible:false,
                        active:null,
                        list:[
                        ],

                        adding:false,
                        addRules:{
                                name:[
                                        {
                                                required:true,message:'收货人必填',trigger:'blur'
                                        },
                                        {
                                                min:2,message:'收货人姓名不能是1个字',trigger:'blur'
                                        }
                                ],
                                phone:[
                                        {
                                                required:true,message:'手机号必填',trigger:'blur'
                                        },
                                        {
                                                min:11,max:11,message:'手机号必须是11位数',trigger:'blur'
                                        }
                                ],
                                addr:[
                                        {
                                                required:true,message:'地址必填',trigger:'blur',
                                        },
                                        {
                                                min:10,message:'地址过短，请填写详细地址',trigger:'blur',
                                        }
                                ]
                        },
                        addForm:{
                                name:'',
                                phone:'',
                                addr:''
                        }
                }
        }
}
</script>
<style lang="less" scoped>
/deep/
input::-webkit-outer-spin-button,
/deep/
 input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
/deep/
 input[type="number"] {
  -moz-appearance: textfield;
}
/deep/
        .el-dialog{
                margin-bottom: 0!important;
                display: flex;
                flex-direction: column;
                /deep/
                .el-dialog__body{
                        flex:1;
                        overflow: auto;
                        padding:0 20px 20px 20px !important;
                        display: flex;
                        flex-direction: column;
                }
        }
        @media screen and (min-width: 768px) {
                /deep/
                .el-dialog{
                        overflow: auto!important;
                }
        }
        @media screen and (max-width: 768px) {
                /deep/
                .el-dialog{
                        margin-top:0!important;
                        height:100%!important;
                        width:100%!important;
                        overflow: auto!important;
                }
        }
        .address{
                width:100%;
                >.list{
                        height: auto;
                        max-height: 500px;
                        text-align: left;
                        padding-bottom: @pd-md;;
                        >.item{
                                cursor: pointer;
                                padding:@pd-sm;
                                border-radius:@radius-sm;
                                &:not(:last-child) {
                                        margin-bottom: @pd-sm;
                                }
                                >.addr{
                                        color:@color-3;
                                        font-size: @size-md;
                                        padding:0 @pd-sm;
                                }
                                >.other{
                                        color:@color-4;
                                        display: flex;
                                        padding:0 @pd-sm;
                                        >.name{
                                                font-size: @size-sm;
                                        }
                                        >.phone{
                                                font-size: @size-sm;
                                                flex:1;
                                                text-align: right;
                                        }
                                }
                        }
                }
        }
</style>