<template>
        <div class="fixed-tools">
                <cart />
        </div>
</template>
<script>
import cart from '../cart/cart.vue'
export default {
        components:{
                cart
        }
}
</script>
<style lang="less" scoped>
        .fixed-tools{
                position: fixed;
                right:20px;
                bottom:40px;
                padding:10px;
                background-color: @bg-color;
                box-shadow: @shadow;
                z-index:9999;
                border-radius: 100px;
        }
</style>