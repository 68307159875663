<template>
        <div class="top-bar">
                <el-popover @show="load_areas" class="no-border" ref="area" placement="bottom-start" width="300" trigger="click">
                        <div v-loading="loading_zone">
                        <div v-if="zone">
                                <div class="size-sm color-info mb">当前位置：</div><span class="color-primary size-lg">{{zone.label+'站'}}</span>
                        </div>
                        <div class="mt">
                                <el-button @click="setArea(item)" type="text" size="small" :class="zone?(zone.id == item.id?'color-primary':'color-default'):''" v-for="(item,index) in zone_list" :key="index">{{item.label}}</el-button>
                        </div>
                        </div>
                </el-popover>
                <div class="main">
                        <el-row :gutter="20">
                                <el-col :span="10">
                                        <span class="text" v-if="zone">
                                                欢迎!
                                                <el-divider direction="vertical"></el-divider>
                                                {{zone.label}}站
                                        </span>
                                                <el-link type="primary" :underline="false" v-popover:area>
                                                        切换
                                                </el-link>
                                </el-col>
                                <el-col :span="14" class="text-right">
                                        <el-link :underline="false" href="/">首页</el-link>
                                        <el-divider direction="vertical"></el-divider>
                                        <el-link :underline="false">我是厂商</el-link>
                                        <el-divider direction="vertical"></el-divider>
                                        <el-link :underline="false" href="/login.html" v-if="!is_login">登陆/注册</el-link>
                                        <el-link :underline="false" href="/mine.html" v-else type="primary">{{$store.getters.get_mine_username}}，个人中心</el-link>
                                </el-col>
                        </el-row>
                </div>
                
        </div>
</template>
<script>
import Area from './area.vue'
export default {
        components:{
                Area
        },
        created() {
                var defaultZone = {
                        id:1,
                        label:'浙江测试'
                };
                var zone = JSON.parse(localStorage.getItem('zone')) || defaultZone;
                this.zone = zone;
                localStorage.setItem('zone',JSON.stringify(zone))
                //
                let token = localStorage.getItem('token');
                this.is_login = token?true:false;
                if(this.is_login && !this.mine_info) {
                        this.$store.dispatch('load_mine_info',this.$api.mine_info)
                }
        },
        methods:{
                async load_areas() {
                        this.zone_list = [];
                        this.loading_zone = true;
                        let data = await this.$api.zone_list();
                        this.loading_zone = false;
                        if(data) this.zone_list = data;
                },
                setArea( item ) {
                        localStorage.setItem('zone',JSON.stringify(item));
                        //this.zone = item;
                        window.location.reload();
                }
        },
        data() {
                return {
                        is_login:false,
                        zone:null,
                        loading_zone:false,
                        zone_list:[
                                
                        ]
                }
        }
}
</script>
<style lang="less" scoped>
        .el-divider__text,
        .el-link{
                font-weight: normal;
                font-size: 12px;
        }
        .top-bar{
                width:100%;
                color:@color-2;
                background-color: @bg-color;
                padding:@pd-md @pd-md;
                border-bottom: @bd;
                box-sizing: border-box;
                >.main{
                        width:100%;
                        max-width: @max-width;
                        margin:0 auto;
                        box-sizing: border-box;
                        padding: 0 @pd-md;
                        .text{
                                font-size: @size-sm;
                                margin-right: 8px;
                        }
                }
        }
</style>