
export const getQuery = () => {
        let query = location.search.substring(1)
        if(query=='' || query.length==0) return {};
        let vars = query.split('&');
        var obj = {}
        for(var i=0;i<vars.length;i++) {
                let [key,value] = vars[i].split('=')
                obj[key] = typeof value == 'undefined'?null:value;
        }
        return obj;
}

var timer;//防抖
export const debounce = (fn,delay) => {
        clearTimeout(timer)
        timer = setTimeout(fn,delay)
}
export default {
        debounce
}