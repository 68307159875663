<template>
        <div class="cart-item">
                <el-image :src="item.thumb" class="item-thumb bd"></el-image>
                <div class="item-info">
                        <div class="title">{{item.name}}
                                <div class="color-info">
                                        规格：{{item.sku_name}}
                                </div>
                        </div>
                        <div class="data">
                                <el-input-number v-if="!readonly" @change="change(item)" v-model="item.count" :min="1" size="mini" label="描述文字"></el-input-number>
                                <span v-else>x {{item.count}}</span>
                        </div>
                </div>
                <div class="price"><span class="label">¥</span>{{item.price}}</div>
                <el-button v-if="!readonly" @click="del(item)" class="delete no-mg" size="small" circle icon="el-icon-delete"></el-button>
        </div>
</template>
<script>
export default {
        props:{
                item:Object,
                readonly:Boolean
        },
        data() {
                return {
                        count:0
                }
        },
        methods:{
                change(item) {
                        this.$emit('count',item)
                },
                del(item) {
                        this.$emit('delete',item)
                }
        }
}
</script>
<style lang="less" scoped>
        /deep/
        .el-message-box{
                width:300px!important;
                max-width:100%!important;
        }
        .cart-item{
                display: flex;
                align-items: center;
                padding:@pd-xs 0;
                &:not(:last-child) {
                        border-bottom: 1px solid @bd-color;
                }
                >.item-thumb{
                        width:80px;
                        height:80px;
                        border:1px solid @bd-color;
                }
                >.item-info{
                        flex:1;
                        width:0;
                        padding-left: @pd-sm;
                        >.title{
                                font-size: @size-sm;
                                text-overflow: -o-ellipsis-lastline;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                display: -webkit-box;
                                -webkit-line-clamp: 2;
                                line-clamp: 2;
                                -webkit-box-orient: vertical;
                        }
                        >.data{
                                display: flex;
                                align-items: center;
                                padding-top: 10px;
                                
                        }
                       
                }
                 >.price{
                        font-size: @size-md;
                        color:@primary-color;
                        text-align: right;
                        padding:0 @pd-md;
                        >.label{
                                font-size: @size-sm;
                        }
                }
                >.delete{
                        margin-left:@pd-lg;
                }
        }
</style>