<template>
        <el-card @click.native="$emit('click',item)" shadow="never" class="good-item-square no-radius alpha-border mb" :body-style="{padding:0}">
                <div class="image">
                        <img :src="item.thumb" :alt="item.name">
                        <div class="view-bar">查看详情</div>
                </div>
                <div class="info">
                        <div class="top">
                                <div class="price">¥<strong>{{item.price}}</strong></div>
                                <div class="volume">销量：{{item.volume}}</div>
                        </div>
                        <div class="name">{{item.name}}</div>
                        
                        <div v-if="showFactory" class="factory-info" @click.stop="$emit('factory-click',item)">
                                <i class="el-icon-s-shop"></i>
                                <span>{{item.factory.name}}</span>
                        </div>
                </div>
        </el-card>
</template>
<script>
export default {
        props:{
                item:Object,
                showFactory:{
                        default:true
                }
        },
}
</script>
<style lang="less" scoped>
        .good-item-square{
                cursor: pointer;
                transition: all 0s;
                position: relative;
                &:hover{
                        border:1px solid @primary-color!important;
                        .image>.view-bar{
                                display: block;
                        }
                }
                .image{
                        position: relative;
                        width:100%;
                        overflow: hidden;
                        background-color:#eee;
                        &:before{
                                content:'';
                                display: block;
                                margin-top: 100%;
                        }
                        >img{
                                width:100%;
                                height:100%;
                                display: block;
                                position: absolute;
                                top:0;
                                left:0;right:0;bottom:0;
                        }
                        >.view-bar{
                                padding:@pd-sm;
                                background-color: @primary-color;
                                color:#fff;
                                position: absolute;
                                left:0;
                                bottom:0;
                                right:0;
                                text-align:center;
                                font-size: @size-md;
                                display: none;
                        }
                }
                .info{
                        padding:@pd-md;
                        >.top{
                                display: flex;
                                >.price{
                                        color:@primary-color;
                                        font-size: 20px;
                                }
                                >.volume{
                                        flex:1;
                                        text-align: right;
                                        font-size: @size-sm;
                                        color:@color-3;
                                }
                        }
                        >.name{
                                padding-top: @pd-sm;
                                padding-bottom: @pd-sm;
                                font-size: @size-sm;
                                line-height: 1.5;
                                color:@color-2;
                                text-overflow: ellipsis;
                                white-space: nowrap;
                                overflow: hidden;
                        }
                        >.factory-info{
                                padding-top: @pd-sm;
                                border-top:1px solid @bd-color;
                                font-size: @size-sm;
                                color:@color-4;
                                text-overflow: ellipsis;
                                white-space: nowrap;
                                overflow: hidden;
                                &:hover{
                                        color:@color-1;
                                }
                        }
                }
        }
</style>