<template>
        <div class="cart-list" v-loading="loading">
                <div class="pd-y" v-if="cart_list.length == 0">
                        <a-empty description="空空如也"/>
                </div>
                <div class="factory mb" v-for="(item,index) in cart_list" :key="index">
                        <div class="factory-name" @click="toFactoryDetail(item.factory_id)">
                                <i class="el-icon-s-shop"></i>
                                {{item.factory_name}}
                        </div>
                        <cart-item :readonly="readonly" v-for="(good,i) in item.goods" :key="i" :item="good" @count="countChange" @delete="del(good,cart_list[index])" />
                        <el-form label-position="left" label-width="100px" class="pt-md" v-if="isconfirm">
                                <el-form-item class="text-left" label="店铺合计">
                                        <span class="color-primary">¥{{item.total_price}}</span>
                                </el-form-item>
                        </el-form>
                </div>
        </div>
</template>
<script>
import Vue from 'vue'
import {Empty} from 'ant-design-vue'
Vue.use(Empty)
import CartItem from './cart-item.vue'
export default {
        props:{
                readonly:Boolean,
                isconfirm:Boolean
        },
        components:{
                CartItem
        },
        data() {
                return {
                        loading:false,
                        cart_list:[]
                }
        },
        computed:{
                computed_list() {
                        var goods = [];
                        this.cart_list.forEach((item,index) => {
                                goods.push(...item.goods)
                        })
                        return goods.map( good => {
                                return {
                                        id:good.id,
                                        sku_id:good.sku_id,
                                        count:good.count
                                }
                        });
                },
        },
        created() {
                this.loadCartList()
        },
        methods:{
                del(good,factory) {
                        let index = factory.goods.indexOf(good)
                        factory.goods.splice(index,1)
                        if(factory.goods.length == 0) {
                                let i = this.cart_list.indexOf(factory);
                                this.cart_list.splice(i,1)
                        }
                        this.$emit('change',this.computed_list)
                },
                countChange() {
                        this.$emit('change',this.computed_list)
                },
                toFactoryDetail(f_id) {

                },
                onmsg() {
                        
                },
                async loadCartList() {
                        this.loading = true;
                        let data = await this.$api.cart_list();
                        this.loading = false;
                        this.cart_list = data.list;
                        this.$emit('load',data);
                }
        }
}
</script>
<style lang="less" scoped>
        .cart-list{
                height:100%;
                overflow-x: hidden;
                overflow-y: auto;
                >.factory{
                        margin-bottom: 20px;
                        >.factory-name{
                                padding:@pd-sm 0;
                                font-size: @size-sm;
                                color:@color-3;
                        }
                }
        }
</style>